// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px
}

.topBarImageAlignment {
    text-align: center;
}

body a:hover {
    color: $color-template;
}

.theme-light .form input,
.theme-light .form textarea {
    color: #f17038;

    color: black;
}

.themeColorText {
    color: #f17038;
}

.paginationcss {
    margin-right: 0;
}

.topbarMenu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
}

.theme-white {
    // border: 1px solid #ddd !important;
    background: white !important
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}


// topbar menu styles end
.form input {
    background: white;
    margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
    display: none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
    display: none;
}

.p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
    width: 100%
}

.p-column-filter-menu-button,
.p-column-filter-clear-button {
    color: white
}

body .p-datatable .p-datatable-tbody>tr.p-highlightHoliday {
    background-color: #fbff2b;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightWeekend {
    background-color: #1efd00;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: $color-white;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
    height: 32px;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

body .p-button {
    background-color: $button-bg-color;
    border-color: $button-hover-color;
    color: $button-text-color;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        background-color: $button-bg-color;
        border-color: $button-hover-color !important;
        color: $button-text-color !important;
    }
}

.p-button:enabled:hover,
.p-button:enabled:active {
    background-color: $button-hover-color;
    border-color: $button-hover-color ;
    color: $button-text-color;
}


//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}

.p-autocomplete-token.p-highlight {
    margin-bottom: 2px;
}

// Dasboard 
.dashBoardCardWithScroll {
    height: 390px;
}

.p-column-title {
    text-transform: capitalize !important;
    color: black;
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

//kpiTable
.kpiTable {
    background-color: $color-template;
    color: black;
}

.colorLegend {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

body .p-multiselect-panel .p-multiselect-items {
    color: black
}

// body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
//     background-color:$color-template;

// }
.p-checkbox-box,
.p-checkbox-icon {
    &:hover {
        border-color: darken($color-additional, 10%) !important;
    }
}

body .p-checkbox .p-checkbox-box.p-highlight {
    background-color: $color-template;
    border-color: $color-template !important;

    &:hover {
        background-color: $color-template !important;
    }
}

body .p-checkbox .p-checkbox-box.p-highlight {
    background-color: $color-template;
    border-color: $color-template
}

body .p-input-overlay-visible {
    z-index: 9999 !important;
}

.color-picker__popover {
    z-index: 9999;
}

.calendar .rbc-off-range-bg {
    background-color: #e5e5e5;
}

.textElipses {
    font-weight: 500;
    color: $color-template;
    cursor: pointer;
}

// .p-dropdown .p-dropdown-trigger {
//     height: 100%;
// }
// .css-vj8t7z{
//     min-height: 0 !important
// }
// .css-1hwfws3{
//     position: static !important;
// }
// .css-10nd86i react-select{
//     height: 33px
// }
// .css-2o5izw .react-select__control .react-select__control--is-focused{
//     height: 33px;
// }

.botmessagesText {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 8px;
    padding-left: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: 5px;
    align-self: flex-start;
    width: 90%;
    border-color: red
}

.usermessagesText {
    background-color: #f0f1ef;
    padding-top: 5px;
    padding-left: 7px;
    margin-right: 5px;
    margin-top: 7px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 90%;
    text-align: left
}

.p-multiselect-label-container {
    height: 30px;
}

.p-autocomplete-input-token input {
    border: none
}

.logout_modal_width {
    max-width: 400px;
}

.call_action_column {
    text-align: 'center';
    text-decoration: underline;
    color: $color-template;
    font-weight: bold;
    cursor: pointer;
}

.p-datatable-row {
    cursor: initial !important;
}

.p-datatable-row:hover {
    background: transparent
}

.settings_form {
    width: 23%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: solid 1px lightgray;
}

.form .settings_form:focus,
.form .settings_form:active {
    outline: none;
    border-color: #ffcd08;
}

.pending_badge,
.started_badge {
    padding: 5px;
    font-size: 14px;
    margin-bottom: 2px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
}

.vertical_middle {
    justify-content: center;
    align-items: center;
    display: flex;
}

body .p-component {
    font-family: unset;
}

.pagenumber-indicator {
    display: flex;
    height: 100%;
    align-items: center;
}

.rdw-editor-toolbar {
    img {
        height: 20px;
        width: 20px;
    }
}

.p-column-header-content:has(.p-checkbox) {
    justify-content: center;
}

.p-datatable .p-column-header-content {
    display: block;
    display: flex;
}

.p-datatable .p-column-filter-menu-button {
    color: #6b7280 !important;
}

.p-filter-column {
    text-align: center !important;
    padding: 0.35rem !important;

    .p-inputtext {
        padding: 0.25rem 0.75rem;
    }

    .p-column-filter-clear-button {
        display: none;
    }

    .p-multiselect-label {
        padding: 0.25rem 0.75rem;
        color: rgb(100, 94, 94);
    }

}

.p-filter-column {
    text-align: center !important;
    padding: 0.40rem !important;

    .p-inputtext {
        padding: 0.45rem 0.75rem;
    }

    .p-column-filter-clear-button {
        display: none;
    }

    .p-multiselect-label {
        padding: 0.25rem 0.75rem;
        color: rgb(82, 75, 75);
    }

}

.p-autocomplete input {
    width: 100%;
}

.p-button {
    &:hover {
        background: $color-template !important;
        border-color: $color-template
    }
}

p-selectable-row {
    border: 2px solid gray !important;
}

.ck-content {
    min-height: 25vh;
}

.p-button.p-button-icon-only {
    height: 3.2rem;
}

.p-autocomplete-multiple-container {
    width: 100% !important;
}

.custom-radio-buttons>div {
    margin-bottom: 10px;
}

//css for daily & detail view buttons in the timesheet 
.custom-btn {
    display: flex;
    flex: auto;
    justify-content: flex-end;
    gap: 20px;
}

.custom-btn button {
    height: 35px;
    border-radius: 20px;
}

//css for leave balance modal in the employees screen
.leave-modal .modal-content {
    width: 90%;
}

.custom-leave-modal .cardHeader {
    padding: 15px;
}

.custom-leave-modal .form__form-group-label {
    margin: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.custom-leave-modal .form__form-group {
    margin-bottom: 15px;
}

.custom-leave-modal .modal__footer {
    margin-top: 30px;
    margin-bottom: 10px !important;
    justify-content: center !important;
    margin-left: 20rem;
}
//css for settings screen adjustments
.settings-content .p-card-content {
    padding: 0.50rem !important;
}

//css for dropdown values if values has more lenth of text
.p-autocomplete-panel {
    .p-autocomplete-items {
        width: 300px !important;

    }

    .p-autocomplete-item {
        white-space: unset;
        padding: 0.50rem 1rem !important;
    }
}